// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'API endpoints',
    path: '/dashboard/server',
    icon: getIcon('ant-design:api-filled'),
  },
  // {
  //   title: 'API use guide',
  //   path: '/dashboard/apiexample',
  //   icon: getIcon('lucide:webhook'),
  // },
  // {
  //   title: 'dashboard',
  //   path: '/dashboard/app',
  //   icon: getIcon('eva:pie-chart-2-fill'),
  // },

  // {
  //   title: 'user',
  //   path: '/dashboard/user',
  //   icon: getIcon('eva:people-fill'),
  // },
  {
    title: 'payments',
    path: '/dashboard/paylink',
    icon: getIcon('fluent:payment-28-filled'),
  },
  // {
  //   title: 'webhooks',
  //   path: '/dashboard/webhooks',
  //   icon: getIcon('lucide:webhook'),
  // },

  // {
  // {
  //   title: 'blog',
  //   path: '/dashboard/blog',
  //   icon: getIcon('eva:file-text-fill'),
  // },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: getIcon('eva:lock-fill'),
  // },
  // {
  //   title: 'register',
  //   path: '/register',
  //   icon: getIcon('eva:person-add-fill'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: getIcon('eva:alert-triangle-fill'),
  // },
];

export default navConfig;
